import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import NotFound from '@/views/NotFoundPage.vue';


const routes = [
  {
    title: 'Dashboard',
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false}
      },
      {
        path: '/icons',
        name: 'icons',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Icons.vue'),
        meta: {authorze: []},
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/UserProfile.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: false}
      },
      {
        path: '/maps',
        name: 'maps',
        component: () => import(/* webpackChunkName: "demo" */ '../views/GoogleMaps.vue'),
        meta: {authorze: []}
      },
      {
        path: '/tables',
        name: 'tables',
        component: () => import(/* webpackChunkName: "demo" */ '../views/RegularTables.vue'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false}
      },
      {
        path: '/rbstore',
        name: 'RB store',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redbubble.vue'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: true}
      },
      {
        path: '/esstore',
        name: 'ES store',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/crawls/Etsy'),
        meta: {authorze: [], hideSearch: true, hideNavbar: false, hideFooter: true, title: 'Etsy'}
      },
      {
        path: '/merch',
        name: 'Merch',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/crawls/Merch'),
        meta: {authorze: [], hideSearch: true, hideNavbar: false, hideFooter: true, title: 'Merch store'}
      },
      {
        path: '/amstore',
        name: 'Am store',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/crawls/Amazon'),
        meta: {authorze: [], hideSearch: true, hideNavbar: false, hideFooter: true, title: 'A store'}
      },
      {
        path: '/seller',
        name: 'seller',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Seller/Seller'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: false, title: 'Seller'}
      },
      {
        path: '/work',
        name: 'Work',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Work/Work'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: false, title: 'Work'}
      },
      {
        path: '/uploads',
        name: 'Uploads',
        component: () => import(/* webpackChunkName: "demo" */ '../components/Uploads'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: true, title: 'Upload Center'}
      },
      {
        path: '/helium',
        name: 'Keywords',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/helium/Helium'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: false, title: 'Keywords'}
      },
      {
        path: '/inventories',
        name: 'Inventories',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Inventories/Sku'),
        meta: {authorze: [], hideSearch: true, hideNavbar: false, hideFooter: false, title: 'Sku'}
      },
      {
        path: '/store3',
        name: 'Store3',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/crawls/Society6'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: true, title: 'Society6'}
      },
      {
        path: '/place',
        name: 'Places',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Places/Place'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Place'}
      },
      {
        path: '/designs',
        name: 'Designs',
        component: () => import(/* webpackChunkName: "demo" */ '../components/ImagePreview'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: true, title: 'Image'},
      },
      {
        path: '/ranking',
        name: 'Ranking',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Ranking/Rank'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: true, title: 'Ranking'},
      },
      {
        path: '/keywords',
        name: 'Keyword',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Keywords/Keyword'),
        meta: {authorze: [], hideSearch: false, hideNavbar: false, hideFooter: true, title: 'Keyword'},
      },
      {
        path: '/history',
        name: 'History',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/History/History'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'History'},
      },
      {
        path: '/fsgstore',
        name: 'FSG Store',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/DesignStore/DesignStore.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'FSG Store'},
      },
      {
        path: '/shirt',
        name: 'Fesogo',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Fesogo/Shirt.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Shirt'},
      },
      {
        path: '/canvas',
        name: 'Canvas',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Fesogo/Canvas.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Canvas'},
      },
      {
        path: '/poster',
        name: 'Poster',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Fesogo/Poster.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Poster'},
      },
      {
        path: '/poster',
        name: 'Fesogo',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Fesogo/Shirt.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'FSG Store'},
      },
      {
        path: '/redesign',
        name: 'Redesign',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/redesign.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'FSG Store'},
    },
      {
        path: '/finished',
        name: 'Finished',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/finished.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'FSG Store'},
    },
      {
        path: '/etsylisting',
        name: 'Etsylisting',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/etsylisting.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Etsy Listing'},
    },
      {
        path: '/tiktoklisting',
        name: 'Tiktoklisting',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/tiktoklisting.vue'),
        meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Tiktok Listing'},
    },
    {
      path: '/recontent',
      name: 'Recontent',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/recontent.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Re content'},
    },
    {
      path: '/mug',
      name: 'Poster',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/recontentMug.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Mug content'},
    },
    {
      path: '/merch-mug',
      name: 'Mug',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/recontentMugMerch.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Mug content'},
    },
    {
      path: '/blanket',
      name: 'Blanket',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/recontentBlanket.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Re content'},
    },
    {
      path: '/phone-case',
      name: 'Phone case',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/recontentPhoneCase.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Phone case'},
    },
    {
      path: '/pillow',
      name: 'Pillow',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/recontentPillow.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Pillow'},
    },
    {
      path: '/tote',
      name: 'Tote',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/recontentTote.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Re content'},
    },
    {
      path: '/gallery',
      name: 'Gallery',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/gallery.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Gallery'},
    },
    {
      path: '/topimage',
      name: 'Top',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Ranking/TopImage.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Top image'},
    },
    {
      path: '/fsg-nicks',
      name: 'Nick',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Ideas/Nick.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Nick'},
    },
    {
      path: '/fsg-ideas/:nick_id',
      name: 'Ideas',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Ideas/Idea.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Ideas'},
    },
    {
      path: '/fsg-designs',
      name: 'Ideas',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Ideas/Design.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Designs'},
    },
    {
      path: '/fsg-contents',
      name: 'Content',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Ideas/Content.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: true, title: 'Content'},
    },
    {
      path: '/ads',
      name: 'Ads',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Ads.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Ads'},
    },
    {
      path: '/topdesigner',
      name: 'Top',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/TopDesigner/Topdesign.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Top'},
    },
    {
      path: '/spam',
      name: 'spam',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/spam.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Top'},
    },
    {
      path: '/keep_all',
      name: 'keepall',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/fullResults.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Top'},
    },
    {
      path: '/hijack',
      name: 'Hijack',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Redesign/hijack.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Top'},
    },
    {
      path: '/mockups',
      name: 'Mockups',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Mockups.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Top'},
    },
    {
      path: '/author-tts',
      name: 'Author-tts',
      component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/AuthorTts.vue'),
      meta: {authorze: [], hideSearch: true, hideNavbar: true, hideFooter: false, title: 'Top'},
    },
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Register.vue')
      },
      { path: '*', component: NotFound }
    ]
  },
];
export default routes;
