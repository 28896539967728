import axios from 'axios';

const fsg = axios.create({
  baseURL: 'https://v2.fecom.io/',
  // baseURL: 'http://0.0.0.0:5001',
  timeout: 3600 * 1000 * 3,
  headers: {"fsg-token": localStorage.getItem("fsg-token"),
            "employee_id": localStorage.getItem("id"),
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json;charset=UTF-8'
  	    }
})

export default fsg
